<template>
  <div>
    <h5>카드 결제 결과</h5>
    <div class="myp-buy-wrap">
      <div class="tbl-scrolls">
        <table class="tbl01 type01">
          <colgroup>
            <col width="" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">결제 결과</th>
              <td>{{ pgLog.returnMessage }}</td>
            </tr>
            <tr>
              <th scope="row">결제 수단</th>
              <td>{{ pgLog.payMethod }}</td>
            </tr>
            <tr>
              <th scope="row">결제 금액</th>
              <td>{{ `${this.insertCommaToNumber(pgLog.amt)}` }}</td>
            </tr>
            <tr>
              <th scope="row">상품명</th>
              <td>{{ pgLog.goodsName }}</td>
            </tr>
            <tr>
              <th scope="row">구매자 ID</th>
              <td>{{ pgLog.buyerId }}</td>
            </tr>
            <tr>
              <th scope="row">구매자명</th>
              <td>{{ pgLog.buyerName }}</td>
            </tr>
            <tr>
              <th scope="row">Email</th>
              <td>{{ pgLog.buyerEmail }}</td>
            </tr>
            <tr>
              <th scope="row">카드사</th>
              <td>{{ pgLog.cardCompany }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button @click="close">확인</button>
  </div>
</template>
<script>
import { getPaymentResult } from 'Api/modules'
export default {
  data() {
    return {
      query: '',
      pgLog: {
        returnMessage: '',
        payMethod: '',
        amt: '',
        goodsName: '',
        buyerId: '',
        buyerName: '',
        buyerEmail: '',
        cardCompany: '',
      },
    }
  },
  methods: {
    close() {
      if (window.opener) {
        window.opener.postMessage(
          {
            name: 'PAYMENT-COMPLETE',
            resultCode: this.query.resultCode,
            paymentPath: this.query.paymentPath,
          },
          '*',
        )
        window.close()
      } else {
        if (this.query.paymentPath === '1') {
          document.location.href = '/regist-job-finish'
        } else {
          document.location.href = '/payment-finish'
        }
      }
    },
  },
  async created() {
    this.query = this.$route.query
    this.pgLog = await getPaymentResult({ pgLogId: this.query.pgLogId })
  },
}
</script>

<style scoped>
table {
  border-collapse: collapse;
  text-align: left;
  line-height: 1.5;
  border-top: 3px solid #036;
}
h5 {
  padding-top: 10px;
  font-weight: bold;
  vertical-align: middle;
  color: #369;
  text-align: center;
  font-size: 30px;
}
table tbody th {
  width: 150px;
  padding: 10px;
  font-weight: bold;
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
  background: #f3f6f7;
}
table td {
  width: 350px;
  padding: 10px;
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}
</style>
